import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import LayoutBlog from "../layouts/blog"
import PostListCard from "../components/blog/post-list-card"
import LoadMore from "../components/elements/LoadMore"

import { useQueryPosts } from "../hooks/useQueryPosts"

const BlogPage = ({ data }) => {
  const heading = "Статьи о заработке в Интернете, не выходя из дома"
  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/blog", name: "Статьи" },
  ]

  const [feed, setFeed] = useState(data.api.posts.data.feed)
  const [pagination, setPagination] = useState(data.api.posts.data.pagination)
  const [{ loading, response }, fetch] = useQueryPosts()

  const loadMore = () => {
    fetch({
      variables: {
        cursor: pagination.nextPageCursor || "",
        limit: 10,
      },
    })
  }

  useEffect(() => {
    if (!response) return
    setFeed(state => [...state, ...response.feed])
    setPagination(response.pagination)
  }, [response])

  const pageData = {
    showAside: true,
    hero: <h1 className="heading">{heading}</h1>,
    content: (
      <>
        {feed && feed.map(item => <PostListCard key={item.id} post={item} />)}
        <LoadMore
          isShow={pagination.hasNextPage}
          loading={loading}
          onClick={loadMore}
        />
      </>
    )
  }

  return (
    <LayoutBlog breadcrumbs={breadcrumbList} page={pageData}>
      <Seo
        title={heading}
        description="Блог о разработке сайтов и графическом дизайне."
        url="/blog"
      />
    </LayoutBlog>
  )
}

export default BlogPage

export const query = graphql`
  {
    api {
      posts(cursor: "", limit: 10) {
        code
        message
        data {
          feed {
            id
            heading
            title
            description
            slug
            owner {
              id
              name
              image {
                id
                url
                alt
                file {
                  childImageSharp {
                    fixed(width: 36, height: 36) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            category {
              heading
              slug
              icon
            }
            tags {
              id
              heading
              slug
            }
            image {
              id
              url
              alt
              file {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            status
            views
            commentsCount
            createdAt
            updatetAt
            publishedAt
          }
          pagination {
            nextPageCursor
            hasNextPage
          }
        }
      }
    }
  }
`
